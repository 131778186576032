
import { Component } from "vue-property-decorator";
import { Mixins } from "vue-mixin-decorator";
import GeneralMixin from "@/mixin";
import BranchList from "@/components/admin/list/BranchList.vue";
@Component({
  components: { BranchList }
})
export default class extends Mixins<GeneralMixin>(GeneralMixin) {
  public customer_id = 0;
  public created() {
    this.customer_id = Number(this.$route.params.customer_id);
  }
}
